import React from "react";
import { Link } from "react-router-dom";
import { Typography, Button, Box } from "@mui/material";

export const LoginToClaim = ({ invite }) => {
  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Claim Shared Pass
      </Typography>
      <Typography variant="body1" gutterBottom>
        A friend shared a pass with you! Create an account or sign in to claim
        your pass.
      </Typography>
      <Box sx={{ my: 2 }} textAlign={"center"}>
        <Link to="/signin">
          <Button variant="outlined">Sign In or Create an Account</Button>
        </Link>
      </Box>
    </Box>
  );
};
