import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Typography, Box, Button, CircularProgress } from "@mui/material";
import {
  confirmPerformance,
  getPerformerStatus,
} from "../../services/firebase";

const ConfirmPerformance = () => {
  const { performerId } = useParams();
  const [isConfirming, setIsConfirming] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [error, setError] = useState(null);
  const [performerStatus, setPerformerStatus] = useState(null);
  const [discountCode, setDiscountCode] = useState(null);

  useEffect(() => {
    const fetchPerformerStatus = async () => {
      try {
        const status = await getPerformerStatus(performerId);
        setPerformerStatus(status);
      } catch (err) {
        setError("Unable to verify performer status. Please try again later.");
      }
    };

    fetchPerformerStatus();
  }, [performerId]);

  const handleConfirm = async () => {
    setIsConfirming(true);
    try {
      const confirmation = await confirmPerformance(performerId);
      if (confirmation.discountCode) {
        setDiscountCode(confirmation.discountCode);
      }
      setIsConfirmed(true);
    } catch (err) {
      setError(
        err.message ||
          "An error occurred while confirming your performance. Please try again."
      );
    } finally {
      setIsConfirming(false);
    }
  };

  if (isConfirming || performerStatus === null) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box textAlign="center" mt={4}>
        <Typography variant="h5" color="error" gutterBottom>
          {error}
        </Typography>
        <Button variant="contained" onClick={() => window.location.reload()}>
          Try Again
        </Button>
      </Box>
    );
  }

  if (!performerStatus.exists) {
    return (
      <Box textAlign="center" mt={4}>
        <Typography variant="h5" gutterBottom>
          Invalid Performer ID
        </Typography>
        <Typography variant="body1">
          The performer ID provided is not valid. Please check your email for
          the correct link.
        </Typography>
      </Box>
    );
  }

  if (!performerStatus.selected) {
    return (
      <Box textAlign="center" mt={4}>
        <Typography variant="h5" gutterBottom>
          Not Selected for Performance
        </Typography>
        <Typography variant="body1">
          We're sorry, but you were not selected for this performance. Thank you
          for your interest.
        </Typography>
      </Box>
    );
  }

  if (performerStatus.confirmed) {
    return (
      <Box textAlign="center" mt={4}>
        <Typography variant="h5" gutterBottom>
          Performance Already Confirmed
        </Typography>
        <Typography variant="body1">
          You have already confirmed your performance. We look forward to seeing
          you at the Open Mic Night.
        </Typography>
      </Box>
    );
  }

  if (isConfirmed) {
    return (
      <Box textAlign="center" mt={4}>
        <Typography variant="h5" gutterBottom>
          Your performance has been confirmed. Thank you!
        </Typography>
        <Typography variant="body1">
          We look forward to seeing you at the Open Mic Night.
        </Typography>
        {discountCode && (
          <Typography variant="body1" gutterBottom>
            Want to bring a friend for free? Please share this discount code{" "}
            <strong>{discountCode}</strong> for a complimentary ticket for one
            guest. Thank you! This discount can only be used once and is valid
            on the day of the event.
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <Box textAlign="center" mt={4}>
      <Typography variant="h5" gutterBottom>
        Confirm Your Performance
      </Typography>
      <Typography variant="body1" gutterBottom>
        Please click the button below to confirm your attendance for the Open
        Mic Night.
      </Typography>
      <Button variant="contained" onClick={handleConfirm}>
        Confirm Performance
      </Button>
    </Box>
  );
};

export default ConfirmPerformance;
