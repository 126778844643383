import React, { useState, useContext } from "react";
import { Box, TextField, Button, Snackbar } from "@mui/material";
import { getDiscount } from "../services/firebase";
import { CartContext } from "../context/CartContext";

export const DiscountCode = () => {
  const { setDiscount, discountCode } = useContext(CartContext);
  const [inputValue, setInputValue] = useState(discountCode);
  const [showInput, setShowInput] = useState(false);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const applyCode = async () => {
    setError(null);
    if (!inputValue) {
      setError("Please enter a discount code");
      return;
    }
    const validDiscount = await getDiscount(inputValue);
    if (validDiscount.error) {
      setError("Invalid Discount Code");
      return;
    }
    setDiscount(validDiscount);
    setSnackbarOpen(true);
    setShowInput(false);
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  if (!showInput) {
    return (
      <Button onClick={() => setShowInput(true)}>
        {inputValue ? `Change Discount Code` : `Have A Discount Code?`}
      </Button>
    );
  }
  return (
    <>
      <form
        onSubmit={(event) => {
          event.preventDefault(); // This prevents the default form submission behavior
          applyCode(); // Call your applyCode function
        }}
      >
        {error && <Box sx={{ color: "red" }}>{error}</Box>}
        <Box sx={{ py: 2, display: "flex", spacing: 2 }}>
          <TextField
            sx={{ mr: 2 }}
            fullWidth
            name="discountCode"
            label="Have A Discount Code?"
            variant="outlined"
            value={inputValue}
            onChange={(event) => {
              setInputValue(event.target.value);
              if (event.key === "Enter") {
                event.preventDefault();
                applyCode();
              }
            }}
          />
          <Button
            disabled={!inputValue}
            variant="contained"
            onClick={applyCode}
          >
            Apply
          </Button>
        </Box>
      </form>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Discount code applied!"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
    </>
  );
};
