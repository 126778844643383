import Add from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Remove from "@mui/icons-material/Remove";
import Typography from "@mui/material/Typography";

export const QuantitySelector = ({ item, remove, add }) => {
  const quantity = parseInt(item.quantity, 10) || 0;
  const removeItem = (item) => {
    if (quantity < 1) {
      return;
    }
    remove(item);
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        borderRadius: 2,
        p: 1,
      }}
    >
      <IconButton size="xs" sx={{ p: 0.5 }} onClick={() => removeItem(item)}>
        <Remove fontSize="small" />
      </IconButton>
      <Typography variant="body2" p={1}>
        {quantity}
      </Typography>
      <IconButton sx={{ p: 0.5 }} onClick={() => add(item)}>
        <Add fontSize="small" />
      </IconButton>
    </Box>
  );
};
