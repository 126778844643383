import { Box, Typography } from "@mui/material";

export const SharedPass = ({ pass }) => {
  return (
    <Box>
      <Typography variant="overline">{pass.type} Pass</Typography>
      <Typography variant="subtitle1">Status: Shared</Typography>
      <Typography variant="subtitle2">
        Shared with {pass.sharedWith ?? "unknown"}
      </Typography>
    </Box>
  );
};
