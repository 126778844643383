import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { centsToCurrency, getMemberOrders } from "../services/firebase";

import { AppContext } from "../context/AppContext";
import { CreditCardDisplay } from "../components/CreditCardDisplay";
import { timeToDateString } from "../services/utils";
import { useContext } from "react";
import { useParams } from "react-router-dom";

export const ReceiptScreen = () => {
  const { orderId } = useParams();
  const [currentOrder, setCurrentOrder] = useState(null);

  const { member } = useContext(AppContext);

  useEffect(() => {
    if (!member || !member.id) {
      return;
    }
    getMemberOrders(member.id).then((orders) => {
      const order = orders.find((order) => order.id === orderId);

      setCurrentOrder(order);
      return order;
    });
  }, [member, orderId]);

  if (!currentOrder) {
    return <div>Loading...</div>;
  }

  return (
    <Paper style={{ padding: "20px" }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Order Details" />
            <CardContent>
              <Typography variant="subtitle1">
                Order Date: {timeToDateString(currentOrder.createdate)}
              </Typography>
              <Typography variant="body1">
                Amount: {centsToCurrency(currentOrder.amount)}
              </Typography>
              <Typography variant="body1">
                Postal Code:{" "}
                {currentOrder.sourceId?.details?.billing?.postalCode}
              </Typography>
              <Typography variant="body1">
                Status: {currentOrder.status}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h5">Items</Typography>
              {currentOrder.lineItems.map((item) => (
                <Typography variant="body1">
                  {item.name} - {item.quantity} - {centsToCurrency(item.amount)}
                </Typography>
              ))}
            </CardContent>
          </Card>
        </Grid>
        {currentOrder.sourceId?.details?.card && (
          <Grid item xs={12} sm={7} md={5} lg={5}>
            <CreditCardDisplay {...currentOrder.sourceId.details.card} />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default ReceiptScreen;
