import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  getClubPassTemplates,
  getDiscount,
  logEvent,
} from "../services/firebase";

import firebase from "firebase/compat/app";
import { useSearchParams } from "react-router-dom";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [searchParams] = useSearchParams();
  const dsc = searchParams.get("dsc");

  const [cartItems, setCartItems] = useState(() => {
    const savedCartItems = localStorage.getItem("cartItems");
    return savedCartItems ? JSON.parse(savedCartItems) : [];
  });

  const [discount, setDiscount] = useState(() => {
    const savedDiscount = localStorage.getItem("discount");
    return savedDiscount ? JSON.parse(savedDiscount) : null;
  });

  const applyDiscount = useCallback(async (discountCode) => {
    try {
      const validDiscount = await getDiscount(discountCode);
      if (!validDiscount.error) {
        setDiscount(validDiscount);
      }
    } catch (error) {
      console.error("Error applying discount:", error);
    }
  }, []);

  useEffect(() => {
    if (dsc) {
      applyDiscount(dsc);
    }
  }, [dsc, applyDiscount]);

  const addToCart = useCallback((item, selectedOptions = []) => {
    setCartItems((prevCartItems) => {
      const optionsPart =
        selectedOptions.length > 0 ? `-${selectedOptions.join("-")}` : "";
      const sku = `${item.id}${optionsPart}`;
      const newItem = { ...item, sku, selectedOptions };

      const isItemInCart = prevCartItems.find(
        (cartItem) => cartItem.sku === newItem.sku
      );
      if (isItemInCart) {
        return prevCartItems.map((cartItem) =>
          cartItem.sku === newItem.sku
            ? { ...cartItem, quantity: cartItem.quantity + 1 }
            : cartItem
        );
      } else {
        return [
          ...prevCartItems,
          { ...newItem, quantity: newItem.quantity || 1 },
        ];
      }
    });

    logEvent(firebase.analytics.EventName.ADD_TO_CART, {
      currency: "USD",
      value: item.amount,
      items: [item],
    });
  }, []);

  const addDayPassToCart = useCallback(async () => {
    const templates = await getClubPassTemplates();
    const dayPass = templates.find((pass) => pass.type === "day");
    addToCart(dayPass);
  }, [addToCart]);

  const removeFromCart = useCallback((item) => {
    setCartItems((prevCartItems) => {
      const itemInCart = prevCartItems.find((cartItem) =>
        item.sku ? cartItem.sku === item.sku : cartItem.sku === item.id
      );
      if (!itemInCart) return prevCartItems;
      if (itemInCart.quantity === 1 || item.quantity === 0) {
        return prevCartItems.filter((cartItem) => cartItem.sku !== item.sku);
      } else {
        return prevCartItems.map((cartItem) =>
          cartItem.sku === item.sku
            ? { ...cartItem, quantity: Math.max(0, cartItem.quantity - 1) }
            : cartItem
        );
      }
    });

    logEvent(firebase.analytics.EventName.REMOVE_FROM_CART, {
      currency: "USD",
      value: item.amount,
      items: [item],
    });
  }, []);

  const clearCart = useCallback(() => {
    setCartItems([]);
    setDiscount(null);
  }, []);
  const getNumberOfItemsInCart = useCallback(() => {
    return cartItems.reduce((total, item) => total + item.quantity, 0);
  }, [cartItems]);

  const getDiscountedPrice = useCallback((item, quantity) => {
    if (!item.quantityDiscount) return item.amount;
    return Object.keys(item.quantityDiscount).reduce((price, qty) => {
      return quantity >= qty ? item.quantityDiscount[qty] : price;
    }, item.amount);
  }, []);

  const getCartSubtotal = useCallback(() => {
    return cartItems.reduce((total, item) => {
      const discountedPrice = getDiscountedPrice(item, item.quantity);
      return total + discountedPrice * item.quantity;
    }, 0);
  }, [cartItems, getDiscountedPrice]);

  const getCartDiscount = useCallback(() => {
    if (!discount) return 0;
    return discount.percentOff
      ? getCartSubtotal() * (discount.percentOff / 100)
      : discount.amountOff;
  }, [discount, getCartSubtotal]);

  const getCartTotal = useCallback(() => {
    return Math.max(getCartSubtotal() - getCartDiscount(), 0);
  }, [getCartSubtotal, getCartDiscount]);

  const getCartItems = useCallback(() => {
    return cartItems.map((item) => ({
      ...item,
      discountedAmount: getDiscountedPrice(item, item.quantity),
    }));
  }, [cartItems, getDiscountedPrice]);

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  useEffect(() => {
    localStorage.setItem("discount", JSON.stringify(discount));
  }, [discount]);

  const contextValue = useMemo(
    () => ({
      cartItems,
      getCartItems,
      addToCart,
      removeFromCart,
      clearCart,
      getCartSubtotal,
      getCartTotal,
      getNumberOfItemsInCart,
      discount,
      setDiscount,
      getCartDiscount,
      addDayPassToCart,
    }),
    [
      cartItems,
      getCartItems,
      addToCart,
      removeFromCart,
      clearCart,
      getCartSubtotal,
      getCartTotal,
      getNumberOfItemsInCart,
      discount,
      setDiscount,
      getCartDiscount,
      addDayPassToCart,
    ]
  );

  return (
    <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
  );
};
