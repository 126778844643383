import { Box, Typography, Button } from "@mui/material";
import React from "react";

export const Claim = ({ handleClaimPass, invite }) => {
  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Claim Shared Pass
      </Typography>
      <Typography variant="body1" gutterBottom>
        A friend shared a pass with you!
      </Typography>
      <Box sx={{ my: 2 }} textAlign={"center"}>
        <Button variant="outlined" onClick={handleClaimPass}>
          Claim your pass
        </Button>
      </Box>
    </Box>
  );
};
