import React, { useEffect, useState } from "react";

import { Box, Fade, Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export const Loading = ({ isLoading, message = "", messages = [] }) => {
  const [currentMessage, setCurrentMessage] = useState(message);
  useEffect(() => {
    if (messages.length > 0 && isLoading) {
      const rotateMessages = setInterval(() => {
        const nextMessage = messages.shift();
        setCurrentMessage(nextMessage);
      }, 1500);
      return () => {
        clearInterval(rotateMessages);
      };
    }
  }, [messages, isLoading]);
  return (
    <Backdrop
      open={isLoading}
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Box
        sx={{
          mb: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        xs={12}
      >
        {!!currentMessage && (
          <Fade in={!!currentMessage}>
            <Typography variant="h3" gutterBottom>
              {currentMessage}
            </Typography>
          </Fade>
        )}
        <CircularProgress color="inherit" />
      </Box>
    </Backdrop>
  );
};
