import { useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { AppContext } from "../context/AppContext";
import { PendingPass } from "./pass/PendingPass";
import { ActivePass } from "./pass/ActivePass";
import { SharedPass } from "./pass/SharedPass";
import { ExpiredPass } from "./pass/ExpiredPass";
import { TicketPass } from "./pass/TicketPass";
const PassRow = ({ pass }) => {
  if (pass.type === "ticket") {
    return <TicketPass pass={pass} />;
  }
  switch (pass.status) {
    case "pending":
      return <PendingPass pass={pass} />;
    case "active":
      return <ActivePass pass={pass} />;
    case "shared":
      return <SharedPass pass={pass} />;
    default:
      return <ExpiredPass pass={pass} />;
  }
};

export const MemberPasses = () => {
  const { memberPasses } = useContext(AppContext);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        my: 2,
      }}
    >
      <Typography variant="h6">Your Passes</Typography>
      {!memberPasses ||
        (memberPasses.length === 0 && (
          <Typography variant="body1">You have no passes.</Typography>
        ))}
      {memberPasses.map((pass) => (
        <Box
          key={pass.id}
          sx={{
            boxShadow: 1,
            mb: 2,
          }}
        >
          <PassRow pass={pass} />
        </Box>
      ))}
    </Box>
  );
};
