import React from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const brandImage = (brand) => {
  switch (brand) {
    case "VISA":
      return { src: "/images/visa.svg", alt: "Visa" };
    case "MASTERCARD":
      return { src: "/images/mastercard.svg", alt: "Mastercard" };
    case "AMEX":
      return { src: "/images/amex.svg", alt: "American Express" };
    case "DISCOVER":
      return { src: "/images/discover.svg", alt: "Discover" };
    default:
      return { src: "/images/visa.svg", alt: "Visa" };
  }
};

export const CreditCardDisplay = ({ brand, last4, expMonth, expYear }) => {
  return (
    <Card
      sx={{
        boxShadow: 1,
        mb: 2,
        borderRadius: 5,
        background: "linear-gradient(45deg, #a1a1A1 30%, #030303 90%)",
        color: "white",
        fontWeight: "bold",
      }}
    >
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Box
              component="img"
              {...brandImage(brand)}
              sx={{
                maxWidth: "100px",
              }}
            />
          </Grid>
          <Grid item xs={12} textAlign={"right"}>
            <Typography variant="body1" sx={{ pt: 10 }}>
              XXXX XXXX XXXX {last4}
            </Typography>
            <Typography variant="body1">
              {expMonth}/{expYear}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
