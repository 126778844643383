import { useContext, useState } from "react";

import { AppContext } from "../context/AppContext";
import { AvailablePass } from "./AvailablePass";
import Avatar from "@mui/material/Avatar";
import { AvatarGradient } from "./AvatarGradient";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Logout from "@mui/icons-material/Logout";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { timeToDateString } from "../services/utils";
import { useNavigate } from "react-router-dom";
import { BudPass } from "./BudPass";

const adminUrl = "http://manageyourclub-admin.web.app";

export const AccountMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { member, isCheckedIn, memberPasses } = useContext(AppContext);
  const navigate = useNavigate();

  const memberPassesIncludeAnnual = memberPasses.some(
    (mp) => (mp.type === "year") & (mp.status === "active")
  );

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const editProfile = () => {
    handleClose();
    navigate("/profile");
  };
  const viewPasses = () => {
    handleClose();
    navigate("/profile/#membership");
  };
  const handleLogout = () => {
    handleClose();
    navigate("/signout");
  };

  const handleSignIn = () => {
    navigate("/signin");
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          ml: 2,
        }}
      >
        {!member ? (
          <Button onClick={handleSignIn} variant="contained" color="secondary">
            Sign In
          </Button>
        ) : (
          <Tooltip title="Account settings">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              {isCheckedIn() ? (
                <AvatarGradient
                  src={member.photoURL}
                  alt={member.displayName}
                />
              ) : (
                <Avatar src={member.photoURL} alt={member.displayName} />
              )}
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          <Box sx={{ textAlign: "left" }}>
            <Typography variant="h6">{member?.displayName}</Typography>
            <Typography variant="subtitle1" gutterBottom>
              Member Since: {timeToDateString(member?.createdate)}
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem>
          <AvailablePass />
        </MenuItem>
        <Divider />
        <MenuItem onClick={editProfile}>
          <ListItemIcon>
            <ManageAccountsIcon />
          </ListItemIcon>
          Edit Profile
        </MenuItem>
        <MenuItem onClick={viewPasses}>
          <ListItemIcon>
            <BookOnlineIcon />
          </ListItemIcon>
          Your Passes
        </MenuItem>
        {member && member.isHost && (
          <MenuItem
            onClick={() => {
              window.open(adminUrl, "_blank");
              handleClose();
            }}
          >
            <ListItemIcon>
              <ManageAccountsIcon />
            </ListItemIcon>
            Admin
          </MenuItem>
        )}
        {memberPassesIncludeAnnual && (
          <MenuItem>
            <ListItemIcon>
              <BudPass />
            </ListItemIcon>
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};
