import { useContext } from "react";
import { Paper, Typography, Box } from "@mui/material";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { ClubLocation } from "../components/ClubLocation";
import { ClubMap } from "../components/ClubMap";
import { MemberCard } from "../components/MemberCard";

export const CheckedIn = () => {
  const { member, memberPasses } = useContext(AppContext);

  if (!member || !memberPasses) {
    return null;
  }

  const currentPass = memberPasses.find((pass) => pass.status === "active");
  if (!currentPass) {
    return (
      <Box>
        Sorry, you don't have a current pass.{" "}
        <Link to="/profile">Go to your profile</Link>
      </Box>
    );
  }

  const expires = currentPass?.dateExpired.toDate().toDateString();

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        pt: 2,
      }}
    >
      <MemberCard />
      <Typography variant="body1" gutterBottom>
        Please show this screen to the host when you arrive.
      </Typography>
      <Box sx={{ my: 2 }}>
        <QRCode
          value={`https://manageyourclub-admin.web.app/#/members/${member.id}/show`}
        />
      </Box>
      <Typography variant="body1" gutterBottom>
        Your pass expires: {expires}
      </Typography>

      <ClubLocation />
      <ClubMap />
    </Paper>
  );
};
export default CheckedIn;
