import { Box, Container, List, ListItem, Typography } from "@mui/material";

export const Waiver = () => {
  return (
    <Container>
      <Box sx={{ py: 1, px: 2 }}>
        <Typography variant="caption" gutterBottom>
          Last Updated: 20 September 2023
        </Typography>
      </Box>
      <Typography id="modal-modal-description" variant="body1" gutterBottom>
        This release and waiver extends to all claims of any kind or nature
        whatsoever for scene or unforeseen, known or unknown Intending to be
        legally bound, I hereby agree that:
      </Typography>
      <Box sx={{ py: 2, px: 2 }} xs={12}>
        <List>
          <ListItem>I am 21 years of age or older</ListItem>
          <ListItem>
            I intend to consume cannabis products that I brought with me to this
            establishment.
          </ListItem>
          <ListItem>
            I understand the laws of the State of Missouri and agree to follow
            all laws and regulations.
          </ListItem>
          <ListItem>
            I understand the rules and regulations of St.Louis Cannabis Club and
            its affiliates and I agree to follow them.
          </ListItem>
          <ListItem>
            I am not under the influence of cannabis or any other drugs or
            substance that can impair my ability to make decisions.
          </ListItem>
          <ListItem>
            I understand that in case that I will have or feel any signs of
            impairment I will not engage in driving.
          </ListItem>
          <ListItem>
            I am submitting this release, waiver of liability, and assumption of
            risk declaration voluntarily and of my own free will.
          </ListItem>
          <ListItem>
            I have no physical or emotional problems, nor any history there of,
            which will impair my ability to participate in cannabis consumption
            at St. Louis Cannabis Club events.
          </ListItem>
        </List>
      </Box>
    </Container>
  );
};
