import { IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Chat from "@mui/icons-material/Chat";
import Facebook from "@mui/icons-material/Facebook";
import Instagram from "@mui/icons-material/Instagram";

const SocialSharingWidget = () => {
  const [socialLinks, setSocialLinks] = useState({
    facebook: "",
    instagram: "",
    discord: "",
  });

  useEffect(() => {
    // Get social links from database
    // ...
    setSocialLinks({
      facebook: "https://www.facebook.com/TheColaLounge",
      instagram: "https://www.instagram.com/thecolalounge",
      discord: "https://discord.gg/Etc8cNfu",
    });
  }, []);

  return (
    <Box display={{ xs: "none", sm: "flex" }}>
      <Tooltip title="Facebook">
        <IconButton
          component="a"
          color="tertiary"
          href={socialLinks.facebook}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Facebook />
        </IconButton>
      </Tooltip>
      <Tooltip title="Instagram">
        <IconButton
          component="a"
          color="tertiary"
          href={socialLinks.instagram}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Instagram />
        </IconButton>
      </Tooltip>
      <Tooltip title="Discord">
        <IconButton
          component="a"
          color="tertiary"
          href={socialLinks.discord}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Chat />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default SocialSharingWidget;
