import { Container, Link, Typography } from "@mui/material";

import { signOut } from "../services/firebase";

export const SignOutPage = () => {
  signOut();
  return (
    <Container maxWidth="sm">
      <Typography variant="h6" align="center">
        You have been signed out.
      </Typography>
      <Typography variant="h6" align="center">
        <Link href="/signin">Sign In </Link>
      </Typography>
    </Container>
  );
};
