import { Box, Typography } from "@mui/material";
import { CheckInButton } from "../CheckInButton";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";

export const ActivePass = ({ pass }) => {
  const { isCheckedIn, checkedInTime } = useContext(AppContext);
  return (
    <>
      <Box
        sx={{
          p: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <Box>
          <Typography
            variant="subtitle1"
            sx={{
              display: "inline-block",
              textTransform: "capitalize",
            }}
          >
            {pass.type} Pass
          </Typography>
          {isCheckedIn() && (
            <Typography variant="body2">
              Checked In: {checkedInTime.toLocaleDateString()}
            </Typography>
          )}
          <Typography variant="body2">
            Expires on: {pass.dateExpired.toDate().toLocaleDateString()}
          </Typography>
        </Box>
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "flex-bottom",
          }}
        >
          <CheckInButton pass={pass} />
        </Box>
      </Box>
    </>
  );
};
