import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  getOpenMicSignups,
  updatePerformerStatus,
  sendPerformerEmails,
} from "../../services/firebase";

const OpenMicAdmin = () => {
  const [signups, setSignups] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  useEffect(() => {
    const fetchSignups = async () => {
      const data = await getOpenMicSignups();
      setSignups(data);
    };
    fetchSignups();
  }, []);

  const handleOrderChange = (id, order) => {
    setSignups(
      signups.map((signup) =>
        signup.id === id ? { ...signup, performanceOrder: order } : signup
      )
    );
  };

  const handleStatusChange = async (id, selected) => {
    await updatePerformerStatus(id, selected);
    setSignups(
      signups.map((signup) =>
        signup.id === id ? { ...signup, selected } : signup
      )
    );
  };

  const handleSendEmails = async () => {
    try {
      const emailsSent = await sendPerformerEmails();
      console.log({ emailsSent });
      setSnackbar({
        open: true,
        message: "Emails sent successfully",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to send emails",
        severity: "error",
      });
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Open Mic Admin
      </Typography>
      <Button variant="contained" color="primary" onClick={handleSendEmails}>
        Send Emails to Performers
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Stage Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Selected</TableCell>
              <TableCell>Performance Order</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {signups.map((signup) => (
              <TableRow key={signup.id}>
                <TableCell>{signup.name}</TableCell>
                <TableCell>{signup.stageName}</TableCell>
                <TableCell>{signup.email}</TableCell>
                <TableCell>
                  <Button
                    variant={signup.selected ? "contained" : "outlined"}
                    onClick={() =>
                      handleStatusChange(signup.id, !signup.selected)
                    }
                  >
                    {signup.selected ? "Selected" : "Not Selected"}
                  </Button>
                </TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    value={signup.performanceOrder || ""}
                    onChange={(e) =>
                      handleOrderChange(signup.id, e.target.value)
                    }
                    disabled={!signup.selected}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
      </Snackbar>
    </Container>
  );
};

export default OpenMicAdmin;
