import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import People from "@mui/icons-material/People";
import ContentCopy from "@mui/icons-material/ContentCopy";

import { createBudPass } from "../services/firebase";
import { Loading } from "./Loading";

export const BudPass = () => {
  const [open, setOpen] = useState(false);
  const [loading, isLoading] = useState(false);
  const [budPass, setBudPass] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [error, setError] = useState(null);

  const openDialog = () => {
    isLoading(true);
    createBudPass().then((response) => {
      if (response.error) {
        setError(response.error);
        isLoading(false);
        return;
      }
      setBudPass(response);
      isLoading(false);
      setOpen(true);
    });
  };

  const copyToClipboard = () => {
    if (budPass?.data?.discountCode) {
      navigator.clipboard.writeText(budPass.data.discountCode).then(() => {
        setSnackbarOpen(true);
      });
    }
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box>
      <Loading
        isLoading={loading}
        message="Creating Bud Pass"
        messages={[
          "Looking up records...",
          "Checking it twice...",
          "Preparing Bud Pass...",
          "Consulting Machine Elves...",
          "Adding extra love...",
          "Telling the server to  hurry up...",
          "Almost there...",
          "Creating Bud Pass...",
        ]}
      />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            Share This Bud Pass With A Friend
          </Typography>
          <Typography variant="body1" gutterBottom>
            Your friend can use this discount code to get a $5 day pass! You can
            create one Bud Pass per day. This discount code is valid until the
            end of the day and can only be used once.
          </Typography>
          {error && (
            <Typography variant="body1" color="error" gutterBottom>
              {error}
            </Typography>
          )}
          <Input
            id="bud-pass"
            fullWidth
            sx={{ fontSize: "1.5em", mt: 2, p: 1 }}
            value={budPass?.data?.discountCode || ""}
            onClick={copyToClipboard}
            variant="outlined"
            readOnly
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={copyToClipboard}>
                  <ContentCopy />
                </IconButton>
              </InputAdornment>
            }
          />
          <Typography variant="caption">
            Click to copy and share with your friend!
          </Typography>
        </DialogContent>
      </Dialog>
      <Button onClick={openDialog} color="primary">
        <People sx={{ mr: 1 }} /> Share A Bud Pass
      </Button>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={closeSnackbar}
        message="Bud Pass Copied!"
      />
    </Box>
  );
};
