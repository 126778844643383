import {
  CLUB_ID,
  auth,
  db,
  getClubInfo,
  getClubPassTemplates,
  getClubProducts,
  getOrCreateMember,
} from "../services/firebase";
import { createContext, useCallback, useEffect, useMemo, useState } from "react";

const passStatusOrder = {
  active: 1,
  pending: 2,
  shared: 3,
  expired: 4,
};

export const AppContext = createContext({
  clubId: CLUB_ID,
  auth: null,
  user: null,
  member: null,
  memberPasses: [],
  passTemplates: [],
  selectedPass: null,
  order: null,
  setSelectedPass: () => {},
  setOrder: () => {},
  setDiscount: () => {},
});

export const AppProvider = ({ children }) => {
  const [isAuthPending, setIsAuthPending] = useState(true);
  const [user, setUser] = useState(null);
  const [member, setMember] = useState(null);
  const [memberPasses, setMemberPasses] = useState([]);
  const [checkedInTime, setCheckedInTime] = useState(
    localStorage.getItem("checkedInTime")
      ? new Date(JSON.parse(localStorage.getItem("checkedInTime")))
      : null
  );
  const [order, setOrder] = useState(
    localStorage.getItem("order")
      ? JSON.parse(localStorage.getItem("order"))
      : null
  );
  const [discount, setDiscount] = useState(null);
  const [clubInfo, setClubInfo] = useState(null);
  const [passTemplates, setPassTemplates] = useState([]);
  const [products, setProducts] = useState(
    localStorage.getItem("products")
      ? JSON.parse(localStorage.getItem("products"))
      : []
  );

  const onCheckIn = useCallback(() => {
    const futureTime = new Date();
    futureTime.setHours(futureTime.getHours() + 4);
    localStorage.setItem("checkedInTime", JSON.stringify(futureTime));
    setCheckedInTime(futureTime);
  }, []);

  const isCheckedIn = useCallback(() => {
    return checkedInTime && new Date() < new Date(checkedInTime);
  }, [checkedInTime]);

  const handleLocalStorage = useCallback((key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  }, []);

  useEffect(() => {
    handleLocalStorage("order", order);
  }, [order, handleLocalStorage]);

  useEffect(() => {
    handleLocalStorage("products", products);
  }, [products, handleLocalStorage]);

  useEffect(() => {
    const fetchClubData = async () => {
      try {
        const templates = await getClubPassTemplates();
        setPassTemplates(templates);

        const info = await getClubInfo();
        setClubInfo(info);

        const products = await getClubProducts();
        setProducts(products);
      } catch (error) {
        console.error("Error fetching club data:", error);
      }
    };

    fetchClubData();
  }, []);

  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((signedInUser) => {
      if (signedInUser) {
        setUser(signedInUser);
      } else {
        setUser(null);
        setMember(null);
        setOrder(null);
        setDiscount(null);
        setMemberPasses([]);
        setCheckedInTime(null);
        localStorage.setItem("checkedInTime", null);
        localStorage.setItem("order", null);
      }
      setIsAuthPending(false);
    });
    return unregisterAuthObserver;
  }, []);

  useEffect(() => {
    if (!user) {
      return;
    }

    let unsubscribe = () => {};

    const setupMemberSubscription = async () => {
      try {
        const memberRef = await getOrCreateMember(user);

        unsubscribe = memberRef.onSnapshot((docSnapshot) => {
          if (docSnapshot.exists) {
            const updatedMember = {
              ...docSnapshot.data(),
              id: docSnapshot.id,
            };
            setMember(updatedMember);
          } else {
            console.log("Member document does not exist after creation.");
          }
        });
      } catch (error) {
        console.error("Error in member subscription setup:", error);
      }
    };

    setupMemberSubscription();

    return () => unsubscribe();
  }, [user]);

  useEffect(() => {
    if (!user) {
      return;
    }
    const unsubscribe = db
      .collection(`clubs/${CLUB_ID}/passes`)
      .where("memberId", "==", user.uid)
      .onSnapshot((snapshot) => {
        const updatedPasses = snapshot.docs
          .map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
          .sort((a, b) => passStatusOrder[a.status] - passStatusOrder[b.status]);
        setMemberPasses(updatedPasses);
      });

    return () => unsubscribe();
  }, [user]);

  const contextValue = useMemo(() => ({
    isAuthPending,
    clubId: CLUB_ID,
    clubInfo,
    user,
    memberPasses,
    member,
    setMember,
    order,
    setOrder,
    passTemplates,
    discount,
    setDiscount,
    products,
    checkedInTime,
    onCheckIn,
    isCheckedIn,
  }), [
    isAuthPending,
    clubInfo,
    user,
    memberPasses,
    member,
    order,
    passTemplates,
    discount,
    products,
    checkedInTime,
    onCheckIn,
    isCheckedIn,
  ]);

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
};
