import { AppProvider } from "../context/AppContext";
import Box from "@mui/material/Box";
import { CartProvider } from "../context/CartContext";
import { ClubHeader } from "./ClubHeader";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { Footer } from "./Footer";
import { Outlet } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../services/theme";

export const Layout = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <AppProvider>
        <CartProvider>
          <CssBaseline />
          <ClubHeader />
          <Container
            maxWidth="md"
            sx={{
              pt: 8,
              px: 0,
              mt: 0,
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Outlet />
            </Box>
            <Footer />
          </Container>
        </CartProvider>
      </AppProvider>
    </ThemeProvider>
  );
};
