import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CheckInButton } from "../CheckInButton";
import {
  timeToDateString,
  timeToTimeString,
  timeToDate,
} from "../../services/utils";
import { SharePassButton } from "../SharePassButton";

export const TicketPass = ({ pass }) => {
  const canCheckIn = () => {
    const today = new Date();
    const startDate = timeToDate(pass.startDate).setHours(0, 0, 0, 0);
    const endDate = timeToDate(pass.endDate).setHours(23, 59, 59, 999);
    return today >= startDate && today <= endDate;
  };

  return (
    <>
      <Box
        sx={{
          p: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <Box>
          <Box
            component="img"
            src={pass.image}
            alt={pass.eventName}
            sx={{
              width: "100%",
              objectFit: "cover",
            }}
          />
        </Box>
        <Box>
          <Typography
            variant="subtitle1"
            sx={{
              display: "inline-block",
              textTransform: "capitalize",
              fontWeight: "bold",
            }}
          >
            {pass.name}
          </Typography>

          <Typography
            variant="body2"
            sx={{
              fontSize: "0.8rem",
            }}
          >
            {`Starts at: ${timeToDateString(
              pass.dateFirstUsed
            )} at ${timeToTimeString(
              pass.dateFirstUsed
            )} until ${timeToDateString(
              pass.dateExpired
            )} at ${timeToTimeString(pass.dateExpired)}`}
          </Typography>
        </Box>
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "flex-bottom",
          }}
        >
          {canCheckIn() ? (
            <CheckInButton pass={pass} />
          ) : (
            <>
              <SharePassButton pass={pass} />
              <Typography variant="body2">
                Available on {`${timeToDateString(pass.dateFirstUsed)}`}
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};
