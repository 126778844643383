export const ClubMap = () => {
  return (
    <iframe
      title="Map to The Cola Private Lounge"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1559.1961481557908!2d-90.23296116739856!3d38.59384000436836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87d8b47231143069%3A0xe19010056acb2b4a!2sThe%20Cola%20Private%20Lounge!5e0!3m2!1sen!2sus!4v1695778565985!5m2!1sen!2sus"
      width={"100%"}
      height={"300"}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  );
};
