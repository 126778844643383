import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';

const RulesModal = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} scroll="paper" maxWidth="md" fullWidth>
      <DialogTitle>Rules, Expectations, and Assumption of Risk</DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6" gutterBottom>
          Rules and Expectations:
        </Typography>
        <Typography paragraph>
          • Vendors are responsible for the setup and teardown of their booth. All booths must be fully operational at least 30 minutes before the event start time unless specified otherwise.
        </Typography>
        <Typography paragraph>
          • Vendors are expected to adhere to all local and state regulations, especially those related to the sale and promotion of cannabis-related products.
        </Typography>
        <Typography paragraph>
          • Vendors acknowledge that they know and understand Missouri state law related to cannabis.
        </Typography>
        <Typography paragraph>
          • Vendors must ensure their area is clean and safe for event guests.
        </Typography>
        <Typography paragraph>
          • Vendors must follow all guidelines issued by the event organizers regarding venue access, parking, and booth positioning.
        </Typography>
        <Typography paragraph>
          • St. Louis Cannabis Club reserves the right to refuse or remove any vendor who fails to comply with the rules or acts inappropriately.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Assumption of Risk:
        </Typography>
        <Typography paragraph>
          By participating in this event, you acknowledge that you assume all risk related to the operation of your booth and the sale or promotion of your products. St. Louis Cannabis Club and its affiliates are not responsible for any loss, damage, or injury incurred during the event. You agree to indemnify and hold harmless St. Louis Cannabis Club from any claims, damages, or liabilities arising from your participation.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RulesModal;