import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Grid,
  Box,
  Snackbar,
  Autocomplete,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Alert from "@mui/material/Alert";
import {
  collection,
  addDoc,
  serverTimestamp,
  getDocs,
} from "firebase/firestore";
import { db } from "../services/firebase";

const YardSalePOS = () => {
  const [items, setItems] = useState([]);
  const [sellerCode, setSellerCode] = useState("YT-");
  const [amount, setAmount] = useState("");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });
  const [sellerOptions, setSellerOptions] = useState([]);

  useEffect(() => {
    const fetchSellerCodes = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "yardsale"));
        const codes = querySnapshot.docs
          .map((doc) => doc.data().sellerCode)
          .filter(Boolean);
        setSellerOptions(codes);
      } catch (error) {
        console.error("Error fetching seller codes:", error);
        setSnackbar({
          open: true,
          message: "Error loading seller codes. Please try again.",
          severity: "error",
        });
      }
    };

    fetchSellerCodes();
  }, []);

  const handleAddItem = () => {
    if (sellerCode && amount) {
      setItems([...items, { sellerCode, amount: parseFloat(amount) }]);
      setSellerCode("");
      setAmount("");
    }
  };

  const handleRemoveItem = (index) => {
    setItems(items.filter((_, i) => i !== index));
  };

  const handleSubmitTransaction = async () => {
    if (items.length === 0) return;

    try {
      const transactionData = {
        items: items,
        totalAmount: items.reduce((sum, item) => sum + item.amount, 0),
        timestamp: serverTimestamp(),
      };

      await addDoc(collection(db, "yardsaleTransactions"), transactionData);

      setSnackbar({
        open: true,
        message: "Transaction submitted successfully!",
        severity: "success",
      });
      setItems([]);
    } catch (error) {
      console.error("Error submitting transaction:", error);
      setSnackbar({
        open: true,
        message: "Error submitting transaction. Please try again.",
        severity: "error",
      });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom sx={{ mt: 4, mb: 2 }}>
        Yard Sale Point of Sale
      </Typography>
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item xs={12} sm={5}>
            <Autocomplete
              options={sellerOptions}
              value={sellerCode}
              onChange={(event, newValue) => {
                setSellerCode(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Seller Code" />
              )}
              freeSolo
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <TextField
              fullWidth
              label="Amount"
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button variant="contained" onClick={handleAddItem} fullWidth>
              Add Item
            </Button>
          </Grid>
        </Grid>

        <List sx={{ mt: 2 }}>
          {items.map((item, index) => (
            <ListItem
              key={index}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleRemoveItem(index)}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemText
                primary={`Seller: ${item.sellerCode}`}
                secondary={`Amount: $${item.amount.toFixed(2)}`}
              />
            </ListItem>
          ))}
        </List>

        <Box sx={{ mt: 3 }}>
          <Typography variant="h6" gutterBottom>
            Total: $
            {items.reduce((sum, item) => sum + item.amount, 0).toFixed(2)}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmitTransaction}
            disabled={items.length === 0}
          >
            Submit Transaction
          </Button>
        </Box>
      </Paper>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default YardSalePOS;
