import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { DayPass } from "../components/offer/DayPass";
import { Header } from "../components/header/Header";
import { Helmet } from "react-helmet";
import Paper from "@mui/material/Paper";
import { Passes } from "../components/Passes";
import { Products } from "../components/Products";

const Home = () => {
  return (
    <Container spacing={2}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Welcome to The Cola St. Louis</title>
      </Helmet>
      <Box sx={{ my: 2 }}>
        <Header />
      </Box>
      <DayPass />
      <Paper elevation={6} sx={{ p: 2, mt: 2, mb: 2 }}>
        <Passes />
      </Paper>
      <Paper elevation={6} sx={{ p: 2, mb: 2 }}>
        <Products />
      </Paper>
    </Container>
  );
};

export default Home;
