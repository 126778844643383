import { AccountMenu } from "./AccountMenu";
import AppBar from "@mui/material/AppBar";
import { AppContext } from "../context/AppContext";
import Box from "@mui/material/Box";
import { CartButton } from "./CartButton";
import Container from "@mui/material/Container";
import SocialSharingWidget from "./SocialSharing";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

export const ClubHeader = () => {
  const { clubInfo } = useContext(AppContext);
  const navigate = useNavigate();
  const navigateToHome = () => navigate("/");
  const navigateToEvents = () => navigate("/events");
  return (
    <AppBar position="fixed" enableColorOnDark>
      <Container maxWidth={"md"}>
        <Toolbar disableGutters>
          <Box
            onClick={navigateToHome}
            sx={{
              p: 0.5,
              mr: 2,
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              borderRadius: 100,
            }}
          >
            <img
              src="/cola-stl.svg"
              alt={clubInfo?.address.name}
              width={50}
              height={50}
            />
          </Box>
          <Typography
            variant="p"
            display={{ xs: "none", sm: "flex" }}
            component={"a"}
            onClick={navigateToHome}
            color={"white"}
            sx={{
              mr: 2,
              cursor: "pointer",
              textDecoration: "none",
            }}
          >
            Home
          </Typography>
          <Typography
            variant="p"
            component={"a"}
            onClick={navigateToEvents}
            color={"white"}
            sx={{
              cursor: "pointer",
              textDecoration: "none",
            }}
          >
            Events
          </Typography>
          <Typography
            variant="p"
            noWrap
            component={"a"}
            onClick={navigateToHome}
            color={"white"}
            sx={{
              mr: 2,
              flexGrow: 1,
              cursor: "pointer",
              textDecoration: "none",
            }}
          ></Typography>
          <SocialSharingWidget />
          <CartButton />
          <AccountMenu />
        </Toolbar>
      </Container>
    </AppBar>
  );
};
