import React from "react";
import { Link } from "react-router-dom";
import { Typography, Button, Box } from "@mui/material";

export const InvalidInviteCode = () => {
  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Invalid Invitation
      </Typography>
      <Typography variant="body1" gutterBottom>
        Sorry, we couldn't find this invitation. It may have been claimed
        already or expired. Please check the link you were provided and try
        again.
      </Typography>
      <Box sx={{ my: 2 }} textAlign={"center"}>
        <Link to="/contact">
          <Button variant="outlined">
            Please contact us if you have any questions.
          </Button>
        </Link>
      </Box>
    </Box>
  );
};
