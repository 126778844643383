import React, { useState } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
export const ProductOptions = ({ product, updateOption }) => {
  const [selectedSize, setSelectedSize] = useState("Medium");

  const handleSizeChange = (event) => {
    const newSize = event.target.value;
    setSelectedSize(newSize);
    updateOption(newSize);
  };
  if (!product) return null;
  if (!product.options) return null;
  if (!product.options.size) return null;

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel>Size</InputLabel>
      <Select value={selectedSize} label="Size" onChange={handleSizeChange}>
        {product.options.size.map((size, index) => (
          <MenuItem key={index} value={size}>
            {size}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
